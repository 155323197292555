<template>
    <div style="position: absolute;background-color: #2d3640; width: 100%; ">
      <loader v-if="!loadedPage"></loader>
      <div v-else>
        <div style="border-bottom: 1px solid #5e6b79; height: 60px; position: fixed; width: 100%; z-index: 9999999; background-color: #2d3640; ">
          <div style=" float: right; padding-top: 15px; margin-right: 15px;">
            <div @click="routeBack()">
              <img style="height: 20px; opacity: 0.7; cursor: pointer;" src="https://gcdn.bionluk.com/site/icon/ic_close_white.svg"/>
            </div>
          </div>
          <div style="padding: 8px 16px;">
            <p style="color: #dde3eb; font-size: 14px;">{{ portfolio.name }}</p>
            <p style="color: #8b95a1; font-size: 12px;"><span style="color: #5e6b79">by </span>
              <router-link :to="`/${portfolio.user.username}`">{{portfolio.user.username}}</router-link>
            </p>
          </div>
        </div>
        <div style="clear: both" class="hiddendiv20"></div>
        <div style="text-align: center; margin: 60px auto 0;">

          <div @click="activateIframe(portfolio)" v-if="!showIframe && portfolio.video_url && (portfolio.file_type === 'audio' || portfolio.file_type === 'video')" class="playIcon" style="text-align: center; width: 100%; margin: 0 auto; ">
            <span :style="'margin-left:-30px;margin-top:' + (screenHeight - 60) / 2 + 'px; width:60px; height:60px; position:absolute; background:white; border: 2px solid white; border-radius: 50%; z-index:999999;'">
              <img style=" flex:1;height: 60px; width: 60px; " :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
            </span>
          </div>

          <div class="mediaArea">
            <iframe v-if="showIframe" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"  width="100%" height="170" frameborder="0" scrolling="no":src="'https://api.bionluk.com/general/video/' + portfolio.uuid+'/mobilex'+screenWidth+'/'"></iframe>
            <div @click="activateIframe(portfolio)" v-if="!showIframe">
              <img class="poster" :style="'width:100%; height:170px; background-color: white;'" :src="portfolio.image_url_original"/>
            </div>
          </div>

          <img v-for="item in portfolio.items" v-if="item.upload_id !== portfolio.upload_id" style="background-color: white!important; margin-top: 20px;  max-width: 100%!important; box-shadow: 0 0 20px rgba(0,0,0,0.8);" :src="item.image_url_original"/>
        </div>
        <div style="clear: both" class="hiddendiv20"></div>
      </div>
    </div>
</template>

<script>

	export default {
	  name: "src-pages-body-showCase-attachment-v1_mobile",
    data() {
			return {

        loadedPage: false,
        portfolio: null,
        foundCategory: null,
        showIframe: null,
        screenWidth: window.innerWidth,
        screenHeight: (window.innerWidth / 1.77)
      }
		},

    methods:{
      activateIframe(portfolio) {
        if(portfolio.video_url && (portfolio.file_type === 'audio' || portfolio.file_type === 'video')) {
          this.showIframe = true;
        }
      }
    },

    created() {
      this.api.seller.getPortfolio(this.$store.state.routerParams[0], "attachment", this.$Progress)
      .then(async ({data}) => {
        let result = data;
        if (result.success) {
        	this.loadedPage = true;
        	this.portfolio = result.data.portfolio;
        	this.foundCategory = await this.findCategoryByID(this.portfolio.category_id);
          this.$store.state.indexHTMLTitle = result.data.meta.title;
          this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

          this.Helper.trackEvents.pageView(this.Helper.projectShowcaseAttachmentPageProps(result.data.portfolio));
        } else {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        }
      })
      .catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      })
    }
	}

</script>

<style scoped>

</style>
